import {SET_USER, SET_USER_NAME, SET_USER_COLOR, SET_USER_TYPE, SET_USER_MEETING_ID} from "../actions/User";

const initialState = {
  name : null,
  color: null,
  type: null,
  meetingId: null, 
  start_recording_button_flag:null,
  end_recording_button_flag:null,
  room_duration:null,
  code_platform:null,
  video_platform:null,
  guide_details:null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        name: action.payload.name,
        color: action.payload.color,
        type: action.payload.type,
        meetingId: action.payload.meetingId,
        start_recording_button_flag:action.payload.start_recording_button_flag,
        end_recording_button_flag:action.payload.end_recording_button_flag,
        room_duration:action.payload.room_duration,
        code_platform:action.payload.code_platform,
        video_platform:action.payload.video_platform,
        guide_details:action.payload.guide_details,
      };
    case SET_USER_NAME:
      return {
        ...state,
        name : action.payload,
      };
    case SET_USER_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        type: action.payload,
      };
      case SET_USER_MEETING_ID:
        return {
          ...state,
          meetingId: action.payload,
        };
    default:
      return state;
  }
};

export default userReducer;
